var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "ma-auto", attrs: { "min-height": "500px" } },
    [
      _c(
        "v-col",
        {
          staticClass: "ma-auto grey lighten-5",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [
          _vm.usuarioPartner
            ? _c("v-img", {
                staticClass: "ma-auto",
                attrs: {
                  "align-center": "",
                  "justify-center": "",
                  src: _vm.logoBvs,
                  width: "20vw",
                  alt: "logo-recuperi"
                }
              })
            : _vm._e(),
          !_vm.usuarioPartner
            ? _c("v-img", {
                staticClass: "ma-auto",
                attrs: {
                  "align-center": "",
                  "justify-center": "",
                  src: _vm.logo,
                  width: "12vw",
                  alt: "logo-recuperi"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "ma-auto", attrs: { sm: "12", md: "8", lg: "8" } },
        [
          _c("p", { staticClass: "text-justify font-weight-black mt-8" }, [
            _vm._v(" Olá " + _vm._s(_vm.nomeDevedor) + ", ")
          ]),
          _c("p", { staticClass: "text-justify mt-8" }, [
            _vm._v(
              " Verificamos que a empresa " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                " solicitou o cancelamento do seu protesto, assumindo todos os custos dos cartórios, conforme anexo. "
            )
          ]),
          _c("p", { staticClass: "text-justify" }, [
            _vm._v(
              " O sistema da Recuperi é 100% eletrônico e não é necessário apresentar documentos adicionais, como a carta de anuência. "
            )
          ]),
          _c("p", { staticClass: "text-justify" }, [
            _vm._v(
              " Nos próximos dias, o seu protesto será retirado automaticamente. Você poderá acompanhar esse processo diretamente junto ao cartório responsável. "
            )
          ])
        ]
      ),
      _c(
        "v-col",
        {
          staticClass: "ma-auto text-center",
          attrs: { sm: "12", md: "8", lg: "8" }
        },
        [
          _c("v-btn", { staticClass: "ma-auto", attrs: { color: "primary" } }, [
            _vm._v(" Ver detalhes do protesto ")
          ]),
          _c("p", { staticClass: "mt-6 caption text-justify" }, [
            _vm._v(
              " Link disponível por 72h a partir do recebimento desse e-mail "
            )
          ])
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "ma-auto text-center",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [_vm._v(" Obrigado, Equipe Recuperi ")]
      ),
      _c(
        "v-col",
        {
          staticClass: "text-center grey lighten-5",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [
          _c("p", { staticClass: "font-weight-light subtitle-2" }, [
            _vm._v(
              " Observação: Essa é uma prévia do e-mail enviado, utilizamos esse modelo para envio a todos os devedores "
            )
          ]),
          _c(
            "v-btn",
            {
              staticClass: "ma-auto",
              attrs: { small: "", color: "grey lighten-3" },
              on: { click: _vm.fecharModal }
            },
            [_vm._v(" Fechar ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }