var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "ma-auto", attrs: { "min-height": "500px" } },
    [
      _c(
        "v-col",
        {
          staticClass: "ma-auto grey lighten-5",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [
          _vm.usuarioPartner
            ? _c("v-img", {
                staticClass: "ma-auto",
                attrs: {
                  "align-center": "",
                  "justify-center": "",
                  src: _vm.logoBvs,
                  width: "20vw",
                  alt: "logo-recuperi"
                }
              })
            : _vm._e(),
          !_vm.usuarioPartner
            ? _c("v-img", {
                staticClass: "ma-auto",
                attrs: {
                  "align-center": "",
                  "justify-center": "",
                  src: _vm.logo,
                  width: "12vw",
                  alt: "logo-recuperi"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "ma-auto", attrs: { sm: "12", md: "8", lg: "8" } },
        [
          _c("p", { staticClass: "text-justify mt-8 font-weight-bold" }, [
            _vm._v(" Olá " + _vm._s(_vm.nomeDevedor) + ", ")
          ]),
          _c("p", [
            _vm._v(
              " Nosso sistema indica que você permanece protestado, pois ainda existem débitos pendentes com a empresa " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                " ou junto aos cartórios de protesto. "
            )
          ]),
          _c("p", { staticClass: "text-justify" }, [
            _vm._v(
              " Para regularizar seu nome, entre em contato com " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                " e negocie suas dívidas. "
            )
          ])
        ]
      ),
      _c(
        "v-col",
        {
          staticClass: "ma-auto text-center",
          attrs: { sm: "12", md: "8", lg: "8" }
        },
        [
          _c("v-btn", { staticClass: "ma-auto", attrs: { color: "primary" } }, [
            _vm._v(" Desejo Negociar minha dívida ")
          ]),
          _c("p", { staticClass: "text-justify caption mt-5" }, [
            _vm._v(
              " Caso você já tenha negociado/quitado sua dívida com o " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                ", fique tranquilo! Em breve, você receberá orientações sobre os próximos passos do seu procedimento de protesto por e-mail. "
            )
          ]),
          _c("p", { staticClass: "text-justify caption mt-5" }, [
            _vm._v(
              " " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                " incentiva que seus clientes procurem o canal oficial de atendimento para negociar diretamente suas dívidas protestadas. "
            )
          ]),
          _c("p", { staticClass: "text-justify caption mt-5" }, [
            _vm._v(
              " O pagamento da presente dívida não impede o protesto de eventuais outros débitos ou parcelas em aberto. "
            )
          ])
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "ma-auto text-center",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [_vm._v(" Obrigado, Equipe Recuperi ")]
      ),
      _c(
        "v-col",
        {
          staticClass: "text-center grey lighten-5",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [
          _c("p", { staticClass: "font-weight-light subtitle-2" }, [
            _vm._v(
              " Observação: Essa é uma prévia do e-mail enviado, utilizamos esse modelo para envio a todos os devedores "
            )
          ]),
          _c(
            "v-btn",
            {
              staticClass: "ma-auto",
              attrs: { small: "", color: "grey lighten-3" },
              on: { click: _vm.fecharModal }
            },
            [_vm._v(" Fechar ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }