var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "ma-auto", attrs: { "min-height": "500px" } },
    [
      _c(
        "v-col",
        {
          staticClass: "ma-auto grey lighten-5",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [
          _vm.usuarioPartner
            ? _c("v-img", {
                staticClass: "ma-auto",
                attrs: {
                  "align-center": "",
                  "justify-center": "",
                  src: _vm.logoBvs,
                  width: "20vw",
                  alt: "logo-recuperi"
                }
              })
            : _vm._e(),
          !_vm.usuarioPartner
            ? _c("v-img", {
                staticClass: "ma-auto",
                attrs: {
                  "align-center": "",
                  "justify-center": "",
                  src: _vm.logo,
                  width: "12vw",
                  alt: "logo-recuperi"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "ma-auto", attrs: { sm: "12", md: "8", lg: "8" } },
        [
          _c("p", { staticClass: "text-justify font-weight-black mt-8" }, [
            _vm._v(" Olá " + _vm._s(_vm.nomeDevedor) + ", ")
          ]),
          _c("p", { staticClass: "text-justify mt-8" }, [
            _vm._v(
              " Obrigado pela negociação/pagamento com " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                " referente ao título " +
                _vm._s(_vm.ds_nosso_numero) +
                ", que se encontra em procedimento de protesto. "
            )
          ]),
          _c("p", { staticClass: "text-justify" }, [
            _vm._v(
              " Caso você seja intimado pelo cartório sobre o protesto desta dívida, fique tranquilo(a)! " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                " já programou o envio da anuência eletrônica do título " +
                _vm._s(_vm.ds_nosso_numero) +
                ". "
            )
          ]),
          _c("p", { staticClass: "text-justify" }, [
            _vm._v(
              " Isso significa que a anuência eletrônica será enviada e processada assim que o procedimento de protesto for finalizado pelo cartório, o que pode levar alguns dias. "
            )
          ]),
          _c("p", { staticClass: "text-justify mt-6" }, [
            _vm._v(
              " Assim que este procedimento for finalizado, você receberá um novo e-mail com orientações e o contato do cartório para então (i) informar ao cartório que você negociou/pagou sua dívida com " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                ", (ii) pagar as custas/emolumentos do cartório e (iii) solicitar a retirada do protesto do seu nome. "
            )
          ]),
          _c("v-col", { staticClass: "ma-auto text-center" }, [
            _vm._v(
              " O valor das custas/emolumentos será informado pelo próprio cartório. Qualquer dúvida, entre em contato com " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                ". "
            )
          ])
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "ma-auto text-center",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [_vm._v(" Atenciosamente, equipe Recuperi ")]
      ),
      _c(
        "v-col",
        {
          staticClass: "text-center grey lighten-5",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [
          _c("p", { staticClass: "font-weight-light subtitle-2" }, [
            _vm._v(
              " Observação: Essa é uma prévia do e-mail enviado, utilizamos esse modelo para envio a todos os devedores "
            )
          ]),
          _c(
            "v-btn",
            {
              staticClass: "ma-auto",
              attrs: { small: "", color: "grey lighten-3" },
              on: { click: _vm.fecharModal }
            },
            [_vm._v(" Fechar ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }